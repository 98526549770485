<template>
    <div class="dark:bg-gray-800 bg-white max-w-xs relative flex flex-row" v-bind:class="{active: isActive}" v-if="available">
        <div class="item dark:border-gray-100 border-gray-300 flex border rounded-md rounded-tl-md rounded-bl-md px-2 py-1">
            <input v-if="selectedItem" 
                class="dark:bg-gray-800 text-gray-800 bg-white dark:text-white rounded-tl-full px-2 text-sm p-1 rounded-bl-full w-fit focus:border-none focus:outline-none" 
                v-model="searchTerm" type="text" 
                :placeholder="selectedItem.name" 
                @keyup="isVisible = !isVisible"
                @click="isVisible = !isVisible" />
            <input v-else 
                class="dark:bg-gray-800 text-gray-800 bg-white dark:text-white rounded-tl-full px-2 text-sm p-1 rounded-bl-full w-fit focus:border-none focus:outline-none" 
                v-model="searchTerm" type="text" placeholder="Search role function" name="input-search"
                @click="isVisible = !isVisible" />
            <button v-if="!isVisible" class="w-10 text-gray-400 flex items-center justify-center px-2 !pr-1 border-l border-gray-300 dark:border-gray-500" 
            @click="isVisible = !isVisible">
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </button>
            <button v-else class="w-10 flex text-gray-400 items-center justify-center px-2 !pr-2 border-l border-gray-300 dark:border-gray-500" @click="reset" >
                <i class="fa fa-times" aria-hidden="true"></i>
            </button>
        </div>
        <div v-if="isVisible" 
            class="dropdown-popover dark:bg-gray-800 dark:text-gray-300 w-full mt-9 rounded bg-white border 
            dark:border-gray-100 border-gray-300 py-2 space-y-1 absolute z-10 transition ease-in-out delay-150 duration-300">
            <span v-if="filteredFuncNames.length == 0" class="w-full text-center text-xs text-gray-400 px-1 pt-1 pb-2 font-bold">No data available</span>
            <div v-else class="options !text-left truncate z-30">
                <ul>
                    <li class="text-xs text-gray-400 px-1 pt-1 pb-2 font-bold border-b dark:border-gray-500 border-gray-200">
                        <div class="px-2">Showing <span class="text-gray-600 dark:text-gray-100">{{ filteredFuncNames.length }}</span> 
                        of <span class="text-gray-600 dark:text-gray-100">{{ filteredFuncNames.length }}</span> results</div>
                    </li>
                    <li class="cursor-pointer px-2 py-3 text-sm dark:hover:bg-gray-700 hover:bg-gray-100 p-1" v-for="(fname, index) in filteredFuncNames" :key="`fname.name-${index}`"  
                    @click="selectItem(fname)" :role-text="fname!=''">
                        {{ fname.name }}
                    </li>
                </ul>
            </div>
        </div>
 </div>
</template>
<script>
import axios from "axios"

export default {
    data() {
        return {
            endpoints: ['functions'],
            load_cnt: 0,      
            searchTerm: '',
            selectedItem: null,
            isVisible: false,
            functions: []
        }
    },
    computed: {
        available() {
            return this.load_cnt == this.endpoints.length
        },
        functionNames() {
            return this.functions.map(f => {
               return { 
                    name: f.Function
                } 
               
            })
        },
        filteredFuncNames() {
            const query = this.searchTerm.toLowerCase();
            if(this.searchTerm === "") {
                return this.functionNames.filter(function(element ) {
                    if (element.name!==''){
                        return element
                }
                });
            }
            var data= this.functionNames.filter(function(element ) {
                if (element.name!==''){
                    return element
                }
                });
            return data.filter((fname) => {
                if (fname!==''){
                return Object.values(fname).some(word => 
                    String(word).toLowerCase().includes(query)
                );
                }
            })
        }
    },
    provides() {
        return {
            selectedRole: this.selectedItem
        }
    },
    emits: ['selectItem'],

    created() {
        for (const val of this.endpoints) {
            let baseUrlDev = 'http://localhost:8080/api/data/'
            let baseUrl = '/api/data/'
            if (process.env.NODE_ENV === 'development') {
                this.fetchData(baseUrlDev, val)
            } else {
                this.fetchData(baseUrl, val)
            }
        }
    },
    methods: {
        fetchData(baseUrl, endpoint) {
            axios
                .get(baseUrl + endpoint)
                .then(res => {
                    if (endpoint == 'title-area-matrix') {
                        this.titleAreaMatrix = res.data
                    } else if (endpoint == 'title-grades-matrix') {
                        this.titleGradesMatrix = res.data
                    } else {
                        this[endpoint] = res.data
                    }
                    this.load_cnt += 1
                    
                    // do additional work in special cases
                    if (endpoint == 'functions') {
                        // set the initial selector to the first value
                        this.roleSelectorValue = this.functions.map(f => f.Function).filter(f => f.length > 0)[0];
                    } else if (endpoint == 'grades') {
                        this.gradeMenuValue = Object.keys(this.grades[0]).filter(g => g != 'Grade' && g.length > 0)[0]
                    }
                })
                .catch(error => {
                    if (error.response.status==404){
                        this.$router.push({  name: 'NotFound'})
                    }
                    throw error
                })
        },
        selectItem(fname) {
            this.$emit('selectItem', fname)
            this.isVisible = false;
            this.selectedItem = fname;
            let term = JSON.stringify(fname.name);
            this.searchTerm = JSON.parse(term);
        },
        reset() {
            this.$emit('selectItem')
            this.isVisible = false;
            this.selectedItem = "";
            this.searchTerm = this.selectedItem;
        }
    }
}
</script>
