import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import '@fortawesome/fontawesome-free/js/all'
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from "@sentry/tracing";

const app = createApp(App);

if (process.env.VUE_APP_ENVIRONMENT !== 'development') {
	Sentry.init({
		app,
		environment: process.env.VUE_APP_ENVIRONMENT,
		release: `${process.env.VUE_APP_NAME}@${process.env.GIT_HASH}`,
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: [
			new BrowserTracing({
				tracingOrigins: [process.env.VUE_APP_API_URL, /^\//],
			}),
		],
		debug: process.env.VUE_APP_ENVIRONMENT  !==  'production',
		tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.2 : 1,
		tracingOptions: {
			trackComponents: true,
		},
		logErrors: process.env.VUE_APP_ENVIRONMENT !== 'production',
		attachProps: true,
		attachStacktrace: true,
	});
}
app.use(router).mount('#app')
