<template>
    <div class="container text-left dark:bg-gray-800 dark:text-white text-sm font-bold px-3 py-2 pb-5">
        <p>
            Last Updated: {{ lastUpdated }}
        </p>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "BaseFooter",

    data() {
        return {
            lastUpdated: null,
            lastUpdatedEndpoint: 'lastUpdated'
        }
    },

    created() {
        let baseUrlDev = 'http://localhost:8080/api/'
        let baseUrl = '/api/'
        if (process.env.NODE_ENV === 'development') {
            this.fetchData(baseUrlDev, this.lastUpdatedEndpoint)
        } else {
            this.fetchData(baseUrl, this.lastUpdatedEndpoint)
        }
    },
  
    methods: {
        fetchData(baseUrl, endpoint) {
            axios
                .get(baseUrl + endpoint)
                .then(res => {
                    this.lastUpdated = res.data
                })
        }
    }
}
</script>
