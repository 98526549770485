<template>
    <div >
        <tracks-details></tracks-details>
        <qualities-details></qualities-details>
        <glossary-details></glossary-details>
    </div>
</template>

<script>
import TracksDetails from './TracksDetails.vue';
import QualitiesDetails from './QualitiesDetails.vue';
import GlossaryDetails from './GlossaryDetails.vue';
export default {
  name: 'MainDetails',
  components: {
    TracksDetails,
    QualitiesDetails,
    GlossaryDetails
  }
}
</script>
