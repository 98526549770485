<template>
    <div class=" container bg-white dark:bg-gray-800" v-if="available">
      <header class="bg-white dark:bg-gray-800 shadow">
      <div class="max-w-none mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight text-gray-900 dark:text-white  p-4" id="explorer">
            {{ btnText }}
          </h1>
        </div>
      </header>
      <main>
        <!-- message -->
        <div class="max-w-none mx-auto py-6 sm:px-6 lg:px-8 pb-16">
          <div class="px-4 py-6 sm:px-0">
            <p class="block flex-1 text-lg dark:text-white px-4">
              {{ findMessage('Role Explorer').Message }}
            </p>
          </div>
        </div>
        <!-- details -->
        <!-- form -->
        <!-- dropdown - role -->
          <div class="max-w-none mx-auto pb-6 sm:px-6 lg:px-8">
          <div class="px-4 py-2 sm:px-0 w-full flex justify-center flex-wrap space-x-2">
            <div class="relative inline-block text-left">
              <label class="block py-2 text-xs text-black-600 text-center dark:text-white">Role Function</label>
              <!-- SearchDropdown -->
              <div>
                <search-dropdown @selectItem="redirectToRoleExplorer"></search-dropdown>
              </div>
               <!-- list -->
              <div
                class="origin-top-right absolute mt-2 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black z-50 ring-opacity-5" 
                :class="roleSelectorDropdownOpen ? 'opacity-100' : 'hidden'">
                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <p 
                    class="block px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-emerald-600 hover:text-white" 
                    v-for="functionName in functionList" :key="'function-select-'+functionName" 
                    @click="roleSelectorValue = functionName; roleSelectorDropdownOpen=false"
                    role="menuitem">{{ functionName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="max-w-none mx-auto pb-6 sm:px-6 lg:px-8">
          <div class="px-4 py-2 sm:px-0 w-full flex justify-center flex-wrap space-x-2">
            <!-- dropdown - level -->
            <div class="relative inline-block text-center pl-2">
              <!-- list 1-->
              <label class="block py-2 text-xs text-gray-600 dark:text-white">Role 1 Level</label>
              <div class="space-x-1">
                <p 
                  href="#" 
                  class="cursor-pointer inline-block px-4 py-2 text-sm text-gray-700 dark:border-gray-500 
                  dark:text-white hover:bg-emerald-500 dark:hover:bg-gray-900 dark:hover:text-emerald-500 
                  hover:text-gray-900 rounded-md border border-gray-300 shadow-sm"
                  :class="levelSelectorValue1 == levelName ? 'bg-emerald-500 text-black dark:bg-gray-900 dark:border-emerald-500 dark:text-emerald-500' : ''"
                  @click="levelSelectorValue1 = levelName; levelSelectorDropdownOpen1=false"
                  v-for="levelName in jobFunctionLevels(roleSelectorValue)" :key="'level-list-'+levelName" role="menuitem"
                >
                  {{levelName }}
                </p>
              </div>
            </div>
            <div class="relative inline-block text-left pl-5">
              <label class="block py-2 text-xs text-black-600 dark:text-white">Show Comparison</label>
              <div class="space-x-1">
                <p
                  href="#" 
                  class="cursor-pointer inline-block px-4 py-2 text-sm text-gray-700 dark:text-white
                  bg-gray-400 hover:bg-emerald-500 dark:bg-gray-600 dark:hover:text-gray-900 
                  dark:hover:bg-emerald-600 hover:text-white rounded-md border border-gray-300 shadow-sm"
                  key="diff-selector"
                  @click="diffRoleExplorer(roleExplorerFacetMenuValue)"
                  role="menuitem"
                >
                  Compare
                </p>
              </div>
            </div>
             <div class="relative inline-block text-center pl-2">
              <!-- list 2-->
              <label class="block py-2 text-xs text-black-600 dark:text-white">Role 2 Level</label>
              <div class="space-x-1">
                <p 
                href="#" 
                  class="cursor-pointer inline-block px-4 py-2 text-sm text-gray-700 dark:border-gray-500
                  dark:text-white hover:bg-emerald-500 dark:hover:bg-gray-900 dark:hover:text-emerald-500
                  hover:text-gray-900 rounded-md border border-gray-300 shadow-sm"
                  :class="levelSelectorValue2 == levelName ? 'bg-emerald-500 text-black dark:bg-gray-900 dark:border-emerald-500 dark:text-emerald-500' : ''"
                  @click="levelSelectorValue2 = levelName; levelSelectorDropdownOpen2=false"
                  v-for="levelName in jobFunctionLevels(roleSelectorValue)" :key="'level-list-'+levelName" role="menuitem"
                >
                  {{levelName }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- role explorer details -->
        <div class="max-w-none mx-auto justify-center pb-10 sm:px-6 lg:px-8 flex">
          <!-- left-nav -->
            <div class="space-x-1">
                <p 
                  class="cursor-pointer inline-block px-4 py-2 text-sm text-gray-700 dark:text-white
                  dark:hover:bg-gray-900 hover:bg-emerald-500 dark:hover:text-emerald-500 hover:text-gray-900
                  rounded-md border dark:border-gray-500 border-gray-300 shadow-sm"
                  :class="roleExplorerFacetMenuValue == roleExplorerFacet ? 'bg-emerald-500 dark:border-emerald-500 dark:bg-gray-900 text-black dark:text-emerald-500' : ''"
                  @click="roleExplorerFacetMenuValue = roleExplorerFacet"
                  v-for="roleExplorerFacet in roleExplorerFacets" :key="'facet-list-'+roleExplorerFacet" role="menuitem"
                >
                  {{roleExplorerFacet}}
                </p>
            </div>
        </div>
          <!-- details of role -->
          <!-- IF Responsibilities -->
          <div class="flex w-full gap-10 ">
            <div class="flex-1 flex justify-center flex-wrap space-2 w-full text-lg text-gray-700 dark:text-white">Role 1</div>
            <div class="flex-1 flex justify-center flex-wrap space-2 w-full text-lg text-gray-700 dark:text-white">Role 2</div>
          </div>
          <div class="max-w-none mx-auto pb-6 sm:px-6 lg:px-8 flex ">
          <!-- details of role -->
          <!-- IF Responsibilities -->
          <div 
            v-if="roleExplorerFacetMenuValue == 'Responsibilities'" 
            class="flex-1 flex flex-wrap space-2w-full border rounded-md shadow-md dark:bg-gray-700 dark:border-gray-500">
            <div class="py-4 px-8 markdown-list-wrapper font-light dark:text-white" id="responsibilities_1"
              v-html="roleExplorer(roleSelectorValue, levelSelectorValue1).responsibilities">
            </div>
          </div>
          <div 
            v-if="roleExplorerFacetMenuValue == 'Responsibilities'" 
            class="flex-1 flex flex-wrap space-2 w-full border rounded-md shadow-md dark:bg-gray-700 dark:border-gray-500">
            <div class="py-4 px-8 markdown-list-wrapper font-light dark:text-white" id="responsibilities_2"
              v-html="roleExplorer(roleSelectorValue, levelSelectorValue2).responsibilities">
            </div>
          </div>
          <!-- IF Skills -->
          <div 
            v-if="roleExplorerFacetMenuValue == 'Skills Required'"
            class="flex-1 flex flex-wrap space-2 w-full border shadow-md rounded-md dark:bg-gray-700 dark:border-gray-500">
            <div class="py-4 px-8 markdown-list-wrapper font-light dark:text-white" id="skills_1"
              v-html="roleExplorer(roleSelectorValue, levelSelectorValue1).skills">
            </div>
          </div>
          <div 
            v-if="roleExplorerFacetMenuValue == 'Skills Required'"
            class="flex-1 flex flex-wrap space-2 w-full border shadow-md rounded-md dark:bg-gray-700 dark:border-gray-500">
            <div class="py-4 px-8 markdown-list-wrapper font-light dark:text-white" id="skills_2"
              v-html="roleExplorer(roleSelectorValue, levelSelectorValue2).skills">
            </div>
          </div>
          </div>
          <!-- IF Grade -->
          <div class="flex w-full justify-center pb-16">
          <div v-if="roleExplorerFacetMenuValue == 'Role Grading'" class="flex-1 flex flex-wrap space-2 w-full ">
            <div v-for="grade in roleExplorer(roleSelectorValue, levelSelectorValue1).grades" :key="'grade-list-'+grade.Grade"
              class="whitespace-normal p-3 ml-3 mb-3 h-64 w-72 bg-white dark:bg-gray-700 text-black hover:text-white 
              hover:bg-emerald-500 dark:hover:bg-gray-900 border rounded-md dark:border-gray-500 shadow-md inline-block overflow-auto">
              <p>
                <span class="  dark:text-white font-bold">{{ grade.Grade }}</span> <br />
                <span class="  dark:text-white">{{ grade.Level }}</span> <br />
              </p>
              <p class="py-1">
                <span class="dark:text-white font-light">{{ grade.Detail }}</span>
              </p>
            </div>
          </div>
          <div v-if="roleExplorerFacetMenuValue == 'Role Grading'" class="flex-1 flex flex-wrap space-2 w-full ">
            <div v-for="grade in roleExplorer(roleSelectorValue, levelSelectorValue2).grades" :key="'grade-list-'+grade.Grade"
              class="whitespace-normal p-3 ml-3 mb-3 h-64 w-72 bg-white  dark:bg-gray-700 text-black hover:text-white hover:bg-emerald-500
              dark:hover:bg-gray-900 border rounded-md  dark:border-gray-500 shadow-md inline-block overflow-auto">
              <p>
                <span class="  dark:text-white font-bold">{{ grade.Grade }}</span> <br />
                <span class="  dark:text-white">{{ grade.Level }}</span> <br />
              </p>
              <p class="py-1">
                <span class="  dark:text-white font-light">{{ grade.Detail }}</span>
              </p>
            </div>
          </div>
          <!-- IF Areas -->
          <div v-if="roleExplorerFacetMenuValue == 'Areas of Focus'" class="flex-1 flex flex-wrap space-2 w-full ">
            <div v-for="area in roleExplorer(roleSelectorValue, levelSelectorValue1).areas" :key="'grade-list-'+area.Area"
              class="whitespace-normal p-3 ml-3 mb-3 h-64 w-72 border dark:border-gray-500 rounded-md shadow-md dark:bg-gray-700 inline-block overflow-auto">
              <p>
                <span class="text-gray-500 dark:text-white font-bold">{{ area.Area }}</span> <br />
              </p>
              <p class="w-full py-2">
                <span class="inline-block rounded-l-lg text-white bg-emerald-500 text-xs py-0.5" 
                :style="{ width: (100 * (area.Count / 20)) + '%' }">{{ 100 * (area.Count / 20) }}%</span>
                <span class="inline-block py-0.5 rounded-r-lg text-xs text-black dark:bg-gray-900 bg-gray-200" 
                :style="{ width: (100 * ((20 - area.Count) / 20)) + '%' }">&nbsp;</span> <br />
              </p>
              <p class="py-1">
                <span class="text-gray-900 dark:text-white font-light">{{ area.Detail }}</span>
              </p>
            </div>
          </div>
          <div v-if="roleExplorerFacetMenuValue == 'Areas of Focus'" class="flex-1 flex flex-wrap space-2 w-full ">
            <div v-for="area in roleExplorer(roleSelectorValue, levelSelectorValue2).areas" :key="'grade-list-'+area.Area"
              class="whitespace-normal p-3 ml-3 mb-3 h-64 w-72 border dark:border-gray-500 rounded-md shadow-md dark:bg-gray-700 inline-block overflow-auto">
              <p>
                <span class="text-gray-500 dark:text-white font-bold">{{ area.Area }}</span> <br />
              </p>
              <p class="w-full py-2">
                <span class="inline-block rounded-l-lg text-white bg-emerald-500 text-xs py-0.5" 
                :style="{ width: (100 * (area.Count / 20)) + '%' }">{{ 100 * (area.Count / 20) }}%</span>
                <span class="inline-block py-0.5 rounded-r-lg text-xs text-black dark:bg-gray-900 bg-gray-200" 
                :style="{ width: (100 * ((20 - area.Count) / 20)) + '%' }">&nbsp;</span> <br />
              </p>
              <p class="py-1">
                <span class="text-gray-900 dark:text-white font-light">{{ area.Detail }}</span>
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
</template>
<script>
import axios from "axios";
import { marked } from "marked";
import SearchDropdown from './SearchDropdown.vue';

export default {
  name: "RoleExplorerDetails",
  props: {
    btnText: {
      type: String
    }
  },
  
  components: {
    SearchDropdown
  },
  data() {
    return {
      // data
      messages: [null],
      responsibilities: null,
      skills: null,
      qualities: null,
      companies: null,
      tracks: null,
      functions: null,
      specialities: null,
      areas: null,
      titleAreaMatrix: null,
      grades: null,
      titleGradesMatrix: null,
      glossary: null,
      endpoints: [
        'messages', 
        'responsibilities', 
        'skills', 
        'qualities', 
        'companies', 
        'tracks', 
        'functions',
        'specialities',
        'areas',
        'title-area-matrix',
        'grades',
        'title-grades-matrix',
        'glossary'
      ],
      // helper
      load_cnt: 0,
      // vue-js ui controls
      gradeMenuValue: '',
      roleExplorerFacetMenuValue: 'Responsibilities',
      roleSelectorDropdownOpen: false,
      roleSelectorValue: '',
      //list 1
      levelSelectorDropdownOpen1: false,
      levelSelectorValue1: '1',
      //list 2
      levelSelectorDropdownOpen2: false,
      levelSelectorValue2: '1',
    }
  },
  computed: {
    available() {
      return this.load_cnt == this.endpoints.length
    },
    gradeLevels() {
      if (this.grades == null) { return [] }
      return Object.keys(this.grades[0]).filter(g => g != 'Grade' && g.length > 0 )
    },
    functionList() {
      if (this.functions == null) { return [] }
      return this.functions.map(f => f.Function).filter(f => f.length > 0)
    },
    roleExplorerFacets() {
      return ['Responsibilities', 'Skills Required', 'Role Grading', 'Areas of Focus']
    }
  },
  mounted() {
      
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://en.wikipedia.org/w/index.php?title=User:Cacycle/diff.js&action=raw&ctype=text/javascript')
    document.head.appendChild(externalScript)
    
    let roleName = this.$route.query.role;
    this.roleSelectorValue = roleName; 
    this.roleSelectorDropdownOpen=false
  },
  watch: {
    '$route' () {
      let roleName = this.$route.query.role;
      this.roleSelectorValue = roleName; 
      this.roleSelectorDropdownOpen=false
    }
  },
  created() {
    this.fetchAllData()
  },
  
  methods: {
    fetchAllData() {
      for (const val of this.endpoints) {
        let baseUrlDev = 'http://localhost:8080/api/data/'
        let baseUrl = '/api/data/'
        if (process.env.NODE_ENV === 'development') {
          this.fetchData(baseUrlDev, val)
        } else {
          this.fetchData(baseUrl, val)
        }
      }
    },
    fetchData(baseUrl, endpoint) {
      axios
        .get(baseUrl + endpoint)
        .then(res => {
          if (endpoint == 'title-area-matrix') {
            this.titleAreaMatrix = res.data
          } else if (endpoint == 'title-grades-matrix') {
            this.titleGradesMatrix = res.data
          } else {
            this[endpoint] = res.data
          }
          this.load_cnt += 1
          
          // do additional work in special cases
          if (endpoint == 'functions') {
            // set the initial selector to the first value
            this.roleSelectorValue = this.functions.map(f => f.Function).filter(f => f.length > 0)[0];
          } else if (endpoint == 'grades') {
            this.gradeMenuValue = Object.keys(this.grades[0]).filter(g => g != 'Grade' && g.length > 0)[0]
          }
        }
        )
        .catch(error => {
          if (error.response.status==404){
            this.$router.push({  name: 'NotFound'})
          }
          throw error
        })
    },
    findMessage(title) {
      return this.messages.find(m => m.Title == title) || {}
    },
    findJobFunction(jobFunction) {
      return this.functions.find(f => f.Function == jobFunction) || {}
    },
    jobFunctionLevels(jobFunction) {
      // provide the levels matching a function
      let levels = [];
      const funcDef = this.findJobFunction(jobFunction)
      const nums = [1,2,3,4,5,6,7];
      for (let i = 0; i < nums.length; i++) {
        if (funcDef['Enabled for Level '+i]) { levels.push(i) }
      }
      return levels
    },
    lookupGradeDetail(gradeName, gradeLevel) {
      return this.grades.find(g => g.Grade == gradeName)[gradeLevel]
    },
    lookupAreaDetail(areaName) {
      return this.areas.find(a => a.Area == areaName).Description
    },
    redirectToRoleExplorer(fname){
      this.$router.push({ path: '/', query: { role: fname.name } })
    },
    diffRoleExplorer(roleExplorerFacetMenuValue) {
      var wikEdDiff = new window.WikEdDiff();
      // if user is on Responsibilities section & Diff has not yet been selected
      if (roleExplorerFacetMenuValue === 'Responsibilities' && !document.getElementById("responsibilities_2").contains(document.getElementById("wikEdDiffContainer"))) {
        var diffHtml = wikEdDiff.diff(
          document.getElementById("responsibilities_1").innerText,
          document.getElementById("responsibilities_2").innerText
        );
       
        document.getElementById("responsibilities_2").innerHTML = diffHtml;
      }
      //  if user is on Skills section & Diff has not yet been selected
      if (roleExplorerFacetMenuValue === 'Skills Required' && !document.getElementById("skills_2").contains(document.getElementById("wikEdDiffContainer"))) {
        var diffHtml2 = wikEdDiff.diff(
          document.getElementById("skills_1").innerText,
          document.getElementById("skills_2").innerText
        );
        document.getElementById("skills_2").innerHTML = diffHtml2;
      }
    },
    roleExplorer(jobFunction, level) {
      // provide the whole structure for a job family + level
      // responsibilities, skills, etc
      let role = {
        'responsibilities': '',
        'skills': '',
        'grades': [],
        'areas': [],
      }
      // responsibilities
      for (const idx in this.responsibilities) {
          const r = this.responsibilities[idx]
        if (r.Function == jobFunction) {
            if ((r[level].length > 0) && (r[level] != undefined))
            role['responsibilities'] += marked(`## ${r.Responsibility}\n${r[level]}`)
        }
      }
      // skills
      for (const idx in this.skills) {
          const r = this.skills[idx]
        if (r.Function == jobFunction) {
            if ((r[level].length > 0) && (r[level] != undefined))
            role['skills'] += marked(`## ${r.Skill}\n${r[level]}`)
        }
      }
      // grades
       for (const idx in this.titleGradesMatrix) {
          const gradeMatrix = this.titleGradesMatrix[idx]
          if (gradeMatrix.Function == jobFunction) {
            if ((gradeMatrix[level].length > 0) && (gradeMatrix[level] != undefined)) {
              let grade = {}
              grade.Grade = gradeMatrix.Grade
              grade.Level = gradeMatrix[level]
              grade.Detail = this.lookupGradeDetail(gradeMatrix.Grade, gradeMatrix[level])
              role['grades'].push(grade)
            }
          }
        }
      // areas
      for (const idx in this.titleAreaMatrix) {
          const areaMatrix = this.titleAreaMatrix[idx]
          if (areaMatrix.Function == jobFunction) {
            if ((areaMatrix[level].length > 0) && (areaMatrix[level] != undefined)) {
              let area = {}
              area.Area = areaMatrix.Area
              area.Count = areaMatrix[level]
              area.Detail = this.lookupAreaDetail(areaMatrix.Area)
              role['areas'].push(area)
            }
          }
        }
      return role
    },
    unMarkedRolExp(jobFunction, level) {
      // provide the whole structure for a job family + level
      // responsibilities, skills, etc
      let role = {
        'responsibilities': '',
        'skills': '',
        'grades': [],
        'areas': [],
      }
      // responsibilities
      for (const idx in this.responsibilities) {
          const r = this.responsibilities[idx]
        if (r.Function == jobFunction) {
            if ((r[level].length > 0) && (r[level] != undefined))
            role['responsibilities'] += (`${r[level]}`)
        }
      }
      // skills
      for (const idx in this.skills) {
          const r = this.skills[idx]
        if (r.Function == jobFunction) {
            if ((r[level].length > 0) && (r[level] != undefined))
             role['skills'] += (`\n${r[level]}`)
        }
      }
      return role
    }
  }
}
</script>

<style>
.wikEdDiffContainer
.wikEdDiffInsert {
  font-weight: bold; background-color: rgb(5 150 105 / var(--tw-bg-opacity));
  color: rgb(255, 255, 255); border-radius: 0.25em; padding: 0.2em 1px;}
.wikEdDiffContainer
.wikEdDiffDelete {
  font-weight: bold; 
  background-color: #de5454; 
  color: rgb(255, 255, 255); 
  border-radius: 0.25em; 
  padding: 0.2em 1px;}
.wikEdDiffContainer
.wikEdDiffFragment {
  white-space: pre-wrap;
  background: transparent;
  border: rgb(243 244 246 / var(--tw-bg-opacity));
  border-width: 0;
  border-radius: 0;
  font-family: sans-serif;
  font-size: 88%;
  line-height: 1.6;
  box-shadow: rgb(243 244 246 / var(--tw-bg-opacity));
  padding: 0;
  margin: 0;}
.wikEdDiffContainer
.wikEdDiffBlock {
  color: black;
  font-weight: bold;
  background-color:#e8e8e8ad;
  border-radius: 0.25em;
  padding: 0.2em 1px;
  margin: 0 1px;
}
.wikEdDiffContainer
.wikEdDiffMarkLeft  {
  font-weight: bold;
  background-color: #e8e8e8ad;
  color: rgba(0, 0, 0);
  border-radius: 0.25em;
  padding: 0.2em;
  margin: 0 1px;
}
.wikEdDiffContainer
.wikEdDiffMarkRight {
  font-weight: bold;
  background-color: #e8e8e8ad;
  color: rgba(0, 0, 0);
  border-radius: 0.25em;
  padding: 0.2em;
  margin: 0 1px;
}
.wikEdDiffContainer
.wikEdDiffDeleteBlank {
  background-color: #de5454;
}
.wikEdDiffContainer
.wikEdDiffNewline {
  background-color: #de5454;
}
.container {
  padding-top: 80px;
  min-width: 100%;
}
</style>
