<template>
  <div class="h-screen" id="app" :class="appTheme">
    <HeaderNav />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import HeaderNav from './components/HeaderNav.vue';
import Footer from './components/Footer.vue';
import feather from 'feather-icons';
export default {
  name: 'App',
  components: {
    HeaderNav,
    Footer
  },
  data: () => {
    return {
      appTheme: localStorage.getItem('theme'),
    };
  },
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  },
  
}
</script>

<style lang="scss">
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: black;
}
</style>
