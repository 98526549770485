<template>
    <div> 
      <!-- ** NavBar ** -->
      <div class="sidebar md:hidden" v-bind:class="{ hidden: isActive }">
        <Sidebar />
      </div>
      <div :style="{ 'margin-left': sidebarWidth }">
      </div>
      <nav class="fixed w-full z-50 bg-gray-800 dark:bg-gray-800 border-y-slate-900 border-gray-400 border-b border-gray-400">
        <div class="max-w-none mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex items-center justify-between h-16 my-2">
            <div class="flex items-center w-full">
              <div class="w-full">
                <div class="flex items-center space-x-4 w-full">
                    <button v-on:click="myFilter" >
                      <i class="fas text-white fa-bars cursor-pointer md:hidden" />
                    </button>
                    <div class="text-white font-sans border-2 border-gray-500 hover:bg-gray-900 hover:text-emerald-500 px-3 py-2 rounded-md font-medium sm:flex">
                      <router-link to="/" >Careers Framework</router-link>
                    </div>
                    <div class="hidden md:flex"> 
                      <div class="flex">
                        <div class="text-white hover:bg-gray-900  hover:text-emerald-500 px-3 py-2 rounded-md font-medium"><router-link to="/Grades" >Grades</router-link></div>
                        <div class="text-white hover:bg-gray-900  hover:text-emerald-500 px-3 py-2 rounded-md font-medium"><router-link to="/About" >About</router-link></div>
                      </div>
                    </div>
                    <div class="switch"> 
                     <!-- Toggle Button -->
                      <!-- Theme switcher large screen -->
                      <theme-switcher
                      :theme="theme"
                      @themeChanged="updateTheme"
                      class="ml-8 bg-primary-light dark:bg-ternary-dark px-3 py-2 shadow-sm rounded-xl cursor-pointer"
                      />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
</template>
<script>
import Sidebar from '@/components/sidebar/SideBar'
import { sidebarWidth } from '@/components/sidebar/state'
import ThemeSwitcher from './ThemeSwitcher.vue';

export default {
  name: "HeaderNav",
  
  components: {
    Sidebar,
    ThemeSwitcher,
  },
  data() {
    return {
      theme: '',
      isActive: false
    }
  },
  setup() {
      return { sidebarWidth }
  },
  created() {
    this.theme = localStorage.getItem('theme') || 'light';
  },
  mounted() {
    
    this.theme = localStorage.getItem('theme') || 'light';
  },
  methods: {
    updateTheme(theme) {
      this.theme = theme;
    },
 
    myFilter: function() {
      this.isActive = !this.isActive;
    }
  }
}
</script>
<style scoped>
/* Toggle Button */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.switch{
  position: absolute;
  right: 10px;

}
</style>
