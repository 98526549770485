<template>
  <div v-if="available">
      <!-- ** Overview ** -->
      <header class="bg-white dark:bg-gray-800 dark:shadow-lg shadow">
      <div class="max-w-none mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight text-gray-900 dark:text-white  p-4" id="overview">
          Overview
        </h1>
      </div>
    </header>
    <main>
      <!-- message -->
      <div class="max-w-none mx-auto py-6 sm:px-6 lg:px-8">
        <div class="px-4 py-6 sm:px-0">
          <p class="block flex-1 text-lg dark:text-white px-4">
            {{ findMessage('Overview').Message }}
          </p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import PageNotFound from './PageNotFound.vue';
export default {
  name: "OverviewDetails",
  PageNotFound,
  
  data() {
    return {
      messages: [null],
      endpoints: ['messages'],
      load_cnt: 0,
    }
  },
  computed: {
    available() {
      return this.load_cnt == this.endpoints.length
    },
  },
  created() {
    for (const val of this.endpoints) {
      let baseUrlDev = 'http://localhost:8080/api/data/'
      let baseUrl = '/api/data/'
      if (process.env.NODE_ENV === 'development') {
        this.fetchData(baseUrlDev, val)
      } else {
        this.fetchData(baseUrl, val)
      }
    }
  },
  methods:{
    fetchData(baseUrl, endpoint) {
      axios
        .get(baseUrl + endpoint)
        .then(res => {
          if (endpoint == 'title-area-matrix') {
            this.titleAreaMatrix = res.data
          } else if (endpoint == 'title-grades-matrix') {
            this.titleGradesMatrix = res.data
          } else {
            this[endpoint] = res.data
          }
          this.load_cnt += 1
          
          // do additional work in special cases
          if (endpoint == 'functions') {
            // set the initial selector to the first value
            this.roleSelectorValue = this.functions.map(f => f.Function).filter(f => f.length > 0)[0];
          } else if (endpoint == 'grades') {
            this.gradeMenuValue = Object.keys(this.grades[0]).filter(g => g != 'Grade' && g.length > 0)[0]
          }
        })
        .catch(error => {
          if (error.response.status==404){
            this.$router.push({  name: 'NotFound'})
          }
          throw error
        })
    },
    findMessage(title) {
      return this.messages.find(m => m.Title == title) || {}
    }
  }
}
</script>

