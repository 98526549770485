<template>
  <div class="container bg-white dark:bg-gray-800 pt-28">
    <overview-details></overview-details>
    <role-details></role-details>
  </div>
</template>

<script>
import RoleDetails from './RoleDetails.vue';
import OverviewDetails from './OverviewDetails.vue';

export default {
  name: 'MainDetails',
  components: {
    OverviewDetails,
    RoleDetails
  }
}
</script>

<style>
.container {
  padding-top: 80px;
  min-width: 100%;
}
</style>
