import {createRouter,createWebHistory} from 'vue-router';
import MainDetails from './components/MainDetails.vue';
import GradesDetails from './components//GradesDetails.vue';
import AboutDetails from './components/AboutDetails.vue';
import RoleExplorerDetails from './components/RoleExplorerDetails.vue';
import PageNotFound from './components/PageNotFound.vue';

const routes = [

    {
      path: "/",
      name: 'Home',
      component: MainDetails
    },
    {
        path: "/RoleExplorer",
        name: 'RoleExplorer',
        component: RoleExplorerDetails
    },
    {
        path: "/Grades",
        name: 'Grades',
        component: GradesDetails
    },
    {
        path: "/About",
        name: 'About',
        component: AboutDetails
    },
    { 
        name: 'NotFound',
        path: '/:pathMatch(.*)*', 
        component: PageNotFound 
    }

  ]

  const router = createRouter({
      history: createWebHistory(process.env.BASE_URL),
      routes
  })
  
export default router
