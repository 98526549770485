<template>
 <div class="toggle-wrapper">
    <label class="toggle">
      <a href="#" @click.prevent="toggleTheme" aria-label="Theme Switcher">
        <i
            v-if="theme === 'light'"
            data-feather="moon"
            class="text-liText-ternary-dark hover:text-gray-400 dark:text-liText-ternary-light dark:hover:text-liBorder-primary-light w-5"
        ></i>
        <i
            v-else
            data-feather="sun"
            class="text-gray-200 hover:text-gray-50 w-5"
        ></i>
    </a>
     
    </label>
  </div>
    
</template>

<script>
export default {
    props: {
        theme: {
            type: String,
            required: true,
        },
    },
    methods: {
        toggleTheme() {
            const newTheme = this.theme === 'light' ? 'dark' : 'light';
            localStorage.setItem('theme', newTheme);
            this.$emit('themeChanged', newTheme);
            this.$router.go();
        },
    },
};
</script>
<style scoped>

.toggler {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
}
</style>
