<template>
<transition name="fade">
  <div class="vue-modal z-40" v-show="open">
      <!-- <transition name="slide"> -->
        <transition name="drop-in">
        <div class="vue-modal-inner" v-show="open">
          <div class="vue-modal-content">
            <slot />
            <div class="close-button">
            <button class="cursor-pointer inline-block px-2 py-0.5 text-sm text-red-700 
              hover:text-red-200 rounded-md border border-red-700 shadow-sm hover:bg-red-700 "
              type="button" @click="$emit('close')">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
</transition>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
export default {
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  setup(_, { emit }) {
    const close = () => {
      emit("close");
    };

    const handleKeyup = (event) => {
      if (event.keyCode === 27) {
        close();
      }
    };

    onMounted(() => document.addEventListener("keyup", handleKeyup));
    onUnmounted(() => document.removeEventListener("keyup", handleKeyup));

    return { close };
  },
  
};
</script>

<style>
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.vue-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.close-button{
  position: absolute;
  top: 20px;
  right: 20px;
}

.vue-modal-inner {
  position: absolute;
  top: -300px;
  right: -5px;
  left: -5px;
  max-width: 1500px;
  margin: 25rem auto;
}

.vue-modal-content {
  position: relative;
  background-color: gray;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  border-radius: 0.3rem;
  padding: 1rem;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.drop-in-enter-active,
.drop-in-leave-active {
  transition: all 0.3s ease-out;
}

.drop-in-enter-from,
.drop-in-leave-to {
  opacity: 0;
  transform: translate(0, -50px);
}
</style>
