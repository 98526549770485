<template>
    <div class="container bg-white dark:bg-gray-800" v-if="available">
        <!-- ** Glossary ** -->
        <header class="bg-white dark:bg-gray-800 shadow">
          <div class="max-w-none mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold leading-tight text-gray-900 dark:text-white p-4" id="glossary">
              Glossary
            </h1>
          </div>
        </header>
        <main>
          <!-- message -->
          <div class="max-w-none mx-auto py-6 sm:px-6 lg:px-8">
            <div class="px-4 py-6 sm:px-0">
               <p class="block flex-1 text-lg dark:text-white px-4">
                {{ findMessage('Glossary').Message }}
              </p>
            </div>
          </div>
          <!-- details -->
          <div class="max-w-none mx-auto pb-6 sm:px-6 lg:px-8">
            <div class="px-4 py-2 sm:px-0 w-full flex rounded-md border dark:border-gray-500 dark:bg-gray-700 flex-wrap">
             <div v-for="defn in glossary" :key="defn.Term" class="flex px-2 py-4 w-full">
                <p class="inline-block dark:text-white font-semibold w-1/5 px-2 h-12">{{ defn.Term }}</p>
                <p class="block dark:text-white flex-1">
                  {{ defn.Definition }}
                </p>
              </div>
            </div>
          </div>
        </main>
      <!-- ** Glossary Ends ** -->
    </div>
</template>
<script>
import axios from "axios";
export default {
  name: "GlossaryDetails",
  
  data() {
    return {
      messages: [null],
      load_cnt: 0,
      glossary: null,
      endpoints: [
        'messages',
        'glossary'
      ],
    }
  },

  computed: {
    available() {
      return this.load_cnt == this.endpoints.length
    },
  },

  created() {
    for (const val of this.endpoints) {
      let baseUrlDev = 'http://localhost:8080/api/data/'
      let baseUrl = '/api/data/'
      if (process.env.NODE_ENV === 'development') {
        this.fetchData(baseUrlDev, val)
      } else {
        this.fetchData(baseUrl, val)
      }
    }
  },
  methods:{
    fetchData(baseUrl, endpoint) {
      axios
        .get(baseUrl + endpoint)
        .then(res => {
          if (endpoint == 'title-area-matrix') {
            this.titleAreaMatrix = res.data
          } else if (endpoint == 'title-grades-matrix') {
            this.titleGradesMatrix = res.data
          } else {
            this[endpoint] = res.data
          }
          this.load_cnt += 1
          
          // do additional work in special cases
          if (endpoint == 'functions') {
            // set the initial selector to the first value
            this.roleSelectorValue = this.functions.map(f => f.Function).filter(f => f.length > 0)[0];
          } else if (endpoint == 'grades') {
            this.gradeMenuValue = Object.keys(this.grades[0]).filter(g => g != 'Grade' && g.length > 0)[0]
          }
        }
        )
        .catch(error => {
          if (error.response.status==404){
            this.$router.push({  name: 'NotFound'})
          }
          throw error
        })
    },
    findMessage(title) {
      return this.messages.find(m => m.Title == title) || {}
    }
  }
}
</script>
<style>
.container {
  padding-top: 80px;
  min-width: 100%;
}
</style>
