<template>
  <div class="container bg-white dark:bg-gray-800 pt-28" v-if="available">
    <!-- ** Grades ** -->
      <header class="bg-white dark:bg-gray-800 shadow dark:shadow-lg">
        <div class="max-w-none mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold leading-tight text-gray-900 dark:text-white p-4" id="grades">
            Grades
          </h1>
        </div>
      </header>
      <main>
        <!-- message -->
        <div class="max-w-none mx-auto py-6 sm:px-6 lg:px-8">
          <div class="px-4 py-6 sm:px-0">
            <p class="block flex-1 text-lg dark:text-white px-4">
          {{ findMessage('Grades').Message }}
        </p>
          </div>
        </div>
        <!-- details -->
        <div class="max-w-none mx-auto justify-center pb-6 sm:px-6 lg:px-8 flex ">
          <!-- left-nav -->
            <div class=" flex space-x-1 pb-10">
                <p href="#"
            class="cursor-pointer inline-block px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-emerald-500 
            dark:hover:bg-gray-900 dark:hover:text-emerald-500 hover:text-gray-900 rounded-md border dark:border-gray-500 border-gray-300 shadow-sm"
            :class="gradeMenuValue == gradeLevel ? 'bg-emerald-500 text-black dark:bg-gray-900 dark:border-emerald-500 dark:text-emerald-500': ''"
            @click="gradeMenuValue = gradeLevel"
            v-for="gradeLevel in gradeLevels" :key="'grade-select-'+gradeLevel" role="menuitem">{{ gradeLevel }}</p>
            </div>
        </div>
        <div class="max-w-none mx-auto pb-6  sm:px-6 lg:px-8 flex ">
          <!-- details of grade -->
          <div class="flex-1 flex flex-wrap justify-center space-2">
            <p v-for="grade in grades" :key="'grade-list-'+grade.Grade" class="whitespace-normal p-3 ml-3 mb-3 h-60 w-56 dark:bg-gray-700 
            dark:border-gray-500 dark:hover:bg-gray-900 rounded-md hover:bg-emerald-500 text-black hover:text-white 
            border border-emerald-500 dark:border-gray-300 shadow-md inline-block overflow-auto">
            <span class=" dark:text-white font-bold py-1  ">{{ grade.Grade }}</span> <br />
            <span class=" dark:text-white font-light  ">{{ grade[gradeMenuValue] }}</span></p>
          </div>
        </div>
      </main>
    <!-- ** Grades Ends ** -->
    </div>
</template>
<script>
import axios from "axios";

export default {
  name: "GradesDetails",
  data() {
    return {
      // data
      messages: null,
      functions: null,
      grades: null,
      titleGradesMatrix: null,
      endpoints: [
        'messages',
        'grades',
        'title-grades-matrix'
      ],
      // helper
      load_cnt: 0,
      // vue-js ui controls
      gradeMenuValue: ''
    }
  },
  computed: {
    available() {
      return this.load_cnt == this.endpoints.length
    },
    gradeLevels() {
      if (this.grades == null) { return [] }
      return Object.keys(this.grades[0]).filter(g => g != 'Grade' && g.length > 0 )
    },
  },
  created() {
    for (const val of this.endpoints) {
      let baseUrlDev = 'http://localhost:8080/api/data/'
      let baseUrl = '/api/data/'
      if (process.env.NODE_ENV === 'development') {
        this.fetchData(baseUrlDev, val)
      } else {
        this.fetchData(baseUrl, val)
      }
    }
  },
  methods:{
    fetchData(baseUrl, endpoint) {
      axios
        .get(baseUrl + endpoint)
        .then(res => {
          if (endpoint == 'title-area-matrix') {
            this.titleAreaMatrix = res.data
          } else if (endpoint == 'title-grades-matrix') {
            this.titleGradesMatrix = res.data
          } else {
            this[endpoint] = res.data
          }
          this.load_cnt += 1
          
          // do additional work in special cases
          if (endpoint == 'functions') {
            // set the initial selector to the first value
            this.roleSelectorValue = this.functions.map(f => f.Function).filter(f => f.length > 0)[0];
          } else if (endpoint == 'grades') {
            this.gradeMenuValue = Object.keys(this.grades[0]).filter(g => g != 'Grade' && g.length > 0)[0]
          }
        })
        .catch(error => {
          if (error.response.status==404){
            this.$router.push({  name: 'NotFound'})
          }
          throw error
        })
    },
    findMessage(title) {
      return this.messages.find(m => m.Title == title) || {}
    },
    lookupGradeDetail(gradeName, gradeLevel) {
      return this.grades.find(g => g.Grade == gradeName)[gradeLevel]
    },
    roleExplorer(jobFunction, level) {
      // provide the whole structure for a job family + level
      // responsibilities, skills, etc
      let role = {
        'grades': [],
      }
      // grades
      let gradesStr = this.grades;
      for (let idx = 0; idx < gradesStr.length; idx++) {
        const gradeMatrix = this.titleGradesMatrix[idx]
        if (gradeMatrix.Function == jobFunction) {
          if ((gradeMatrix[level].length > 0) && (gradeMatrix[level] != undefined)) {
            let grade = {}
            grade.Grade = gradeMatrix.Grade
            grade.Level = gradeMatrix[level]
            grade.Detail = this.lookupGradeDetail(gradeMatrix.Grade, gradeMatrix[level])
            role['grades'].push(grade)
          }
        }
      }
      return role
    }
  }
}
</script>
<style>
.container {
  padding-top: 80px;
  min-width: 100%;
}
</style>
