<template>
     <div class="container h-full bg-white dark:bg-gray-800 pt-28">
       <div class="page-layout">
        <div class="layout error-content">
            <h1 class="page-title headline-tight">404</h1>
            <p class="sub-head">
                <span class="accent dampen">Shucks, </span>
                <strong class="loud">this page is dead</strong>
            </p>
            <div class="block flex-1 text-emerald-900 dark:text-white px-4">Not to worry, we’ll get you back to the land of the living:</div>
            <div class="block flex-1 text-emerald-900 dark:text-white px-4">
                <router-link to="/">Home</router-link>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
  name: "PageNotFound",
};
</script>
<style>
.container {
  padding-top: 80px;
  min-width: 100%;
}
.error-content {
    text-align: center
}

.page-layout {
    margin: 3.7037037037% 0
}

.layout-wrapper {
    padding: 0 3.7037037037%
}

.layout {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto
}
.page-title {
    color: #046307;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: .5em
}
h1 {
    font-size: 22px
}
.sub-head {
    margin-top: 0 !important;
    display: block
}
.loud {
    color: #046307;
    font-weight: 700;
    text-transform: uppercase
}

.dampen {
    color: #cdcdcf !important
}x

.error-content-links a {
     color: #046307;
    display: inline-block;
    margin: 0 10px;
}

.headline-tight {
    color: #046307;
    margin-bottom: 0 !important
}

.sub-head {
    margin-top: 0 !important;
    display: block
}
.error-content .page-title {
    
    color: #046307;
    font-size: 200px;
    line-height: 200px;
    font-weight: 200
}
</style>
