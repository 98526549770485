<template>
    <div v-if="available">
      <header class="bg-white dark:bg-gray-800 dark:shadow-lg shadow">
        <div class="max-w-none mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold leading-tight text-gray-900 dark:text-white  p-4" id="explorer">
            Role Explorer
          </h1>
          {{ roleText }}
        </div>
      </header>
      <main>
        <!-- message -->
        <div class="max-w-none mx-auto py-6 sm:px-6 lg:px-8">
          <div class="px-4 py-6 sm:px-0">
            <p class="block flex-1 text-lg  dark:text-white px-4">
              {{ findMessage('Role Explorer').Message }}
            </p>
          </div>
        </div>
        <!-- details -->
        <!-- form -->
        <!-- dropdown - role -->
        <div class="max-w-none mx-auto pb-6 sm:px-6 lg:px-8">
          <div class="px-4 py-2 sm:px-0 w-full flex justify-center flex-wrap space-x-2">
            <div class="relative inline-block text-center">
              <label class="block py-2 text-xs text-gray-600 dark:text-white">Role Function</label>
              <!-- button -->
              <div>
                <search-dropdown @selectItem="redirectToRoleExplorer" :visible="true"></search-dropdown>
              </div>
               <!-- list -->
              <div
                class="origin-top-right absolute mt-2 w-72 rounded-md shadow-lg bg-white
                dark:bg-gray-800 hover:bg-emerald-600 ring-1 ring-black ring-opacity-5" 
                :class="roleSelectorDropdownOpen ? 'opacity-100' : 'hidden'">
                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <p 
                    class="block px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-emerald-600 hover:text-white" 
                    v-for="functionName in functionList" :key="'function-select-'+functionName"    
                    @click="roleSelectorValue = functionName; roleSelectorDropdownOpen=false" role="menuitem">
                    {{ functionName }}
                  </p>
                </div>
              </div>
            </div>
            <!-- dropdown - level -->
            <div class="relative inline-block text-center pl-2">
              <!-- list 1-->
              <label class="block py-2 text-xs text-gray-600 dark:text-white">Role Level</label>
              <div class="space-x-1 pb-10">
                <button href="#" 
                  class="cursor-pointer inline-block px-4 py-2 text-sm text-gray-700 
                  dark:text-white hover:bg-emerald-500 dark:hover:bg-gray-900 dark:hover:text-emerald-500 
                  hover:text-gray-900 rounded-md border dark:border-gray-500 border-gray-300 shadow-sm"
                  :class="levelSelectorValue1 == levelName ? 'bg-emerald-500 text-black dark:bg-gray-900 dark:border-emerald-500 dark:text-emerald-500' : ''"
                  @click="levelSelectorValue1 = levelName; levelSelectorDropdownOpen1=false"
                  v-for="levelName in jobFunctionLevels(roleSelectorValue)" :key="'level-list-'+levelName" role="menuitem">
                  {{ levelName }}
                </button>
              </div>
            </div>
            <div class="relative pl-2 inline-block text-center">
              <label class="block py-2 text-xs text-gray-600 dark:text-white">Role Compare</label>
              <!-- button -->
              <div>
                <button 
                  class="cursor-pointer inline-block px-4 py-2 text-sm text-gray-700 
                  dark:text-white dark:bg-gray-600 bg-gray-400 dark:hover:bg-emerald-500 
                  hover:bg-emerald-500 dark:hover:text-black hover:text-gray-900 rounded-md
                  border border-gray-300 shadow-sm" 
                  @click="isOpen = true">
                  Compare
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ModalDetails :open="isOpen" @close="isOpen = !isOpen">
            <role-explorer-details :btn-text="roleSelectorValue"></role-explorer-details>
          </ModalDetails>
        </div>
        <!-- role explorer details -->
        <div class="max-w-none mx-auto justify-center pb-6 sm:px-6 lg:px-8 flex ">
          <!-- left-nav -->
            <div class="space-x-1 pb-10">
                <p 
                  class="cursor-pointer inline-block px-4 py-2 text-sm text-gray-700 dark:text-white
                  dark:hover:bg-gray-900 hover:bg-emerald-500 dark:hover:text-emerald-500 hover:text-gray-900
                  rounded-md border dark:border-gray-500 border-gray-300 shadow-sm"
                  :class="roleExplorerFacetMenuValue == roleExplorerFacet ? 'bg-emerald-500 dark:border-emerald-500 dark:bg-gray-900 text-black dark:text-emerald-500' : ''"
                  @click="roleExplorerFacetMenuValue = roleExplorerFacet"
                  v-for="roleExplorerFacet in roleExplorerFacets" :key="'facet-list-'+roleExplorerFacet" 
                  role="menuitem"
                >
                  {{roleExplorerFacet }}
                </p>
            </div>
        </div>
        <div class="max-w-none mx-52 pb-6 sm:px-6 lg:px-8 flex ">
          <!-- details of role -->
          <!-- IF Responsibilities -->
          <div 
            v-if="roleExplorerFacetMenuValue == 'Responsibilities'" 
            class="flex-1 flex flex-wrap space-2 w-full border rounded-md dark:bg-gray-700 shadow-md dark:border-gray-500">
            <div 
              class="py-4 px-8 markdown-list-wrapper font-light dark:text-white" 
              id="responsibilities_1"
              v-html="roleExplorer(roleSelectorValue, levelSelectorValue1).responsibilities">
            </div>
          </div>
          <!-- IF Skills -->
          <div 
            v-if="roleExplorerFacetMenuValue == 'Skills Required'"
            class="flex-1 flex flex-wrap space-2 w-full border rounded-md dark:bg-gray-700 shadow-md dark:border-gray-500">
            <div 
              class="py-4 px-8 markdown-list-wrapper font-light dark:text-white" 
              id="skills_1"
              v-html="roleExplorer(roleSelectorValue, levelSelectorValue1).skills">
              </div>
          </div>
          </div>
          <!-- IF Grade -->
          <div 
            v-if="roleExplorerFacetMenuValue == 'Role Grading'" 
            class="flex flex-wrap justify-center space-2 w-full">
            <div 
              v-for="grade in roleExplorer(roleSelectorValue, levelSelectorValue1).grades" 
              :key="'grade-list-'+grade.Grade"
              class="whitespace-normal p-3 ml-3 mb-3 h-60 w-56 bg-white  dark:bg-gray-700 text-black hover:text-white 
              hover:bg-emerald-500 dark:hover:bg-gray-900 rounded-md border dark:border-gray-500  dark:border-gray-500 shadow-md inline-block overflow-auto">
              <p>
                <span class=" dark:text-white font-bold">{{ grade.Grade }}</span> <br />
                <span class="dark:text-white">{{ grade.Level }}</span> <br />
              </p>
              <p class="py-1">
                <span class=" dark:text-white font-light">
                  {{ grade.Detail }}
                </span>
              </p>
            </div>
          </div>
          <!-- IF Areas -->
          <div v-if="roleExplorerFacetMenuValue == 'Areas of Focus'" class="flex flex-wrap space-2 w-full justify-center">
            <div 
              v-for="area in roleExplorer(roleSelectorValue, levelSelectorValue1).areas" 
              :key="'grade-list-'+area.Area"
              class="whitespace-normal dark:bg-gray-700  bg-white dark:bg-gray-800 p-3 ml-3 mb-3 h-64 w-72 border
              dark:border-gray-500 rounded-md shadow-md inline-block overflow-auto">
              <p>
                <span class="text-gray-500 dark:text-white font-bold">
                  {{ area.Area }}
                </span><br />
              </p>
              <p class="w-full py-2">
                <span 
                  class="inline-block rounded-l-lg text-white dark:text-black font-bold bg-emerald-500 text-xs py-0.5" 
                  :style="{ width: (100 * (area.Count / 20)) + '%' }">{{ 100 * (area.Count / 20) }}%
                </span>
                <span 
                  class="inline-block rounded-r-lg py-0.5 text-xs text-black dark:bg-gray-900 bg-gray-200" 
                  :style="{ width: (100 * ((20 - area.Count) / 20)) + '%' }">&nbsp;
                </span><br />
              </p>
              <p class="py-1">
                <span class="text-gray-900 dark:text-white font-light">{{ area.Detail }}</span>
              </p>
            </div>
          </div>
      </main>
    </div>
</template>
<script>

import axios from "axios";
import { marked } from "marked";
import ModalDetails from "./ModalDetails.vue";
import RoleExplorerDetails from './RoleExplorerDetails.vue';
import { ref } from "vue";
import SearchDropdown from './SearchDropdown.vue';

export default {
  name: "RoleDetails",
  components: { 
      ModalDetails,
      RoleExplorerDetails,
      SearchDropdown
  },
  data() {
    return {
      // data
      messages: [null],
      responsibilities: null,
      skills: null,
      qualities: null,
      companies: null,
      tracks: null,
      functions: null,
      specialities: null,
      areas: null,
      titleAreaMatrix: null,
      grades: null,
      titleGradesMatrix: null,
      glossary: null,
      endpoints: [
        'messages', 
        'responsibilities', 
        'skills', 
        'qualities', 
        'companies', 
        'tracks', 
        'functions',
        'specialities',
        'areas',
        'title-area-matrix',
        'grades',
        'title-grades-matrix',
        'glossary'
      ],
      // helper
      load_cnt: 0,
      // vue-js ui controls
      gradeMenuValue: '',

      roleExplorerFacetMenuValue: 'Responsibilities',
      roleSelectorDropdownOpen: false,
      roleSelectorValue: '',
      //list 1
      levelSelectorDropdownOpen1: false,
      levelSelectorValue1: '1',
      //list 2
      levelSelectorDropdownOpen2: false,
      levelSelectorValue2: '1',
      text: "hey there"
    }
  },
  setup() {
    const isOpen = ref(false);

    return { isOpen };
  },

  computed: {
    available() {
      return this.load_cnt == this.endpoints.length
    },
    gradeLevels() {
      if (this.grades == null) { return [] }
      return Object.keys(this.grades[0]).filter(g => g != 'Grade' && g.length > 0 )
    },
    functionList() {
      if (this.functions == null) { return [] }
      return this.functions.map(f => f.Function).filter(f => f.length > 0)
    },
    roleExplorerFacets() {
      return ['Responsibilities', 'Skills Required', 'Role Grading', 'Areas of Focus']
    }
  },
  watch: {
    '$route' () {
      let roleName = this.$route.query?.role || 'Software Engineering';
      this.roleSelectorValue = roleName; 
      this.roleSelectorDropdownOpen=false
    }
  },
  mounted() {
    let roleName = this.$route.query.role;
    this.roleSelectorValue = roleName; 
    this.roleSelectorDropdownOpen=false
  },
  created() {
    this.fetchAllData()
  },
  
  methods: {
    fetchAllData() {
      for (const val of this.endpoints) {
        let baseUrlDev = 'http://localhost:8080/api/data/'
        let baseUrl = '/api/data/'
        if (process.env.NODE_ENV === 'development') {
          this.fetchData(baseUrlDev, val)
        } else {
          this.fetchData(baseUrl, val)
        }
      }
    },
    fetchData(baseUrl, endpoint) {
      axios
        .get(baseUrl + endpoint)
        .then(res => {
          if (endpoint == 'title-area-matrix') {
            this.titleAreaMatrix = res.data
          } else if (endpoint == 'title-grades-matrix') {
            this.titleGradesMatrix = res.data
          } else {
            this[endpoint] = res.data
          }
          this.load_cnt += 1
          
          // do additional work in special cases
          if (endpoint == 'functions') {
            // set the initial selector to the first value
            this.roleSelectorValue = this.functions.map(f => f.Function).filter(f => f.length > 0)[0];
          } else if (endpoint == 'grades') {
            this.gradeMenuValue = Object.keys(this.grades[0]).filter(g => g != 'Grade' && g.length > 0)[0]
          }
        }
        )
        .catch(error => {
          if (error.response.status==404){
            this.$router.push({  name: 'NotFound'})
          }
          throw error
        })
    },
    findMessage(title) {
      return this.messages.find(m => m.Title == title) || {}
    },
    findJobFunction(jobFunction) {
      return this.functions.find(f => f.Function == jobFunction) || {}
    },
    jobFunctionLevels(jobFunction) {
        // provide the levels matching a function
        let levels = [];
        const funcDef = this.findJobFunction(jobFunction)
        for (const i in [1,2,3,4,5,6,7]) {
          if (funcDef['Enabled for Level '+i]) { levels.push(i) }
        }
        return levels
      },
    lookupGradeDetail(gradeName, gradeLevel) {
      return this.grades.find(g => g.Grade == gradeName)[gradeLevel]
    },
    lookupAreaDetail(areaName) {
      return this.areas.find(a => a.Area == areaName).Description
    },
    redirectToRoleExplorer(fname){
      this.$router.push({ path: '/', query: { role: fname.name } })
    },
    diffRoleExplorer(roleExplorerFacetMenuValue) {
      var wikEdDiff = new window.WikEdDiff();
      // if user is on Responsibilities section & Diff has not yet been selected
      if (roleExplorerFacetMenuValue === 'Responsibilities' && !document.getElementById("responsibilities_2").contains(document.getElementById("wikEdDiffContainer"))) {
        var diffHtml = wikEdDiff.diff(
          document.getElementById("responsibilities_1").innerText,
          document.getElementById("responsibilities_2").innerText
        );
        document.getElementById("responsibilities_2").innerHTML = diffHtml;
      }
      //  if user is on Skills section & Diff has not yet been selected
      if (roleExplorerFacetMenuValue === 'Skills Required' && !document.getElementById("skills_2").contains(document.getElementById("wikEdDiffContainer"))) {
        var diffHtml2 = wikEdDiff.diff(
          document.getElementById("skills_1").innerText,
          document.getElementById("skills_2").innerText
        );
        document.getElementById("skills_2").innerHTML = diffHtml2;
      }
    },
    roleExplorer(jobFunction, level) {
      // provide the whole structure for a job family + level
      // responsibilities, skills, etc
      let role = {
        'responsibilities': '',
        'skills': '',
        'grades': [],
        'areas': [],
      }
      // responsibilities
      for (const idx in this.responsibilities) {
          const r = this.responsibilities[idx]
        if (r.Function == jobFunction) {
            if ((r[level].length > 0) && (r[level] != undefined))
            role['responsibilities'] += marked(`## ${r.Responsibility}\n${r[level]}`)
        }
      }
      // skills
      for (const idx in this.skills) {
          const r = this.skills[idx]
        if (r.Function == jobFunction) {
            if ((r[level].length > 0) && (r[level] != undefined))
            role['skills'] += marked(`## ${r.Skill}\n${r[level]}`)
        }
      }
      // grades
       for (const idx in this.titleGradesMatrix) {
          const gradeMatrix = this.titleGradesMatrix[idx]
          if (gradeMatrix.Function == jobFunction) {
            if ((gradeMatrix[level].length > 0) && (gradeMatrix[level] != undefined)) {
              let grade = {}
              grade.Grade = gradeMatrix.Grade
              grade.Level = gradeMatrix[level]
              grade.Detail = this.lookupGradeDetail(gradeMatrix.Grade, gradeMatrix[level])
              role['grades'].push(grade)
            }
          }
        }
      // areas
      for (const idx in this.titleAreaMatrix) {
          const areaMatrix = this.titleAreaMatrix[idx]
          if (areaMatrix.Function == jobFunction) {
            if ((areaMatrix[level].length > 0) && (areaMatrix[level] != undefined)) {
              let area = {}
              area.Area = areaMatrix.Area
              area.Count = areaMatrix[level]
              area.Detail = this.lookupAreaDetail(areaMatrix.Area)
              role['areas'].push(area)
            }
          }
        }
      return role
    }
  }
}
</script>
